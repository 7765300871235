// Label
$product-table-compare-cell-label-font: #{rem-calc(12)} / 1.2 $font-primary;
$product-table-compare-cell-label-border-bottom: none;
$product-table-compare-cell-label-margin: rem-calc(0 -10 12);
$product-table-compare-cell-label-padding: rem-calc(10 0 5);
// Remove
$product-table-compare-remove-font: bold #{rem-calc(12)} / 1.2 $font-tertiary;
// Cell
$product-table-compare-cell-padding: rem-calc(0 10 10);
$product-table-compare-cell-padding--large: rem-calc(0 10 12);
// Compare table
$product-table-compare-border-collapse: separate;
$product-table-compare-border-spacing: rem-calc(10);
// Compare table secondary. Page context (PDP)
$product-table-compare-secondary-cell-padding--large: rem-calc(0 10 20);

@import "@lora/05-components/product/product-table-compare";