$search-plp-top-banner-color: color(text);
$search-plp-top-banner-background: color(light);
$search-plp-top-banner-margin: rem-calc(0);
$search-plp-top-banner-margin--large: rem-calc(0 0 44) !default; //!default added to override on local level

$search-plp-top-banner-category-name-display: none;

$search-plp-top-banner-results-count-margin: rem-calc(0);
$search-plp-top-banner-results-count-color: color(text);
$search-plp-top-banner-results-count-font: #{rem-calc(12)} / 3.4 $font-primary;
$search-plp-top-banner-results-count-text-transform: normal;

$search-plp-top-banner-category-cover-display: block;

$search-plp-top-banner-category-description-display: none;
$search-plp-top-banner-category-name-with-category-cover-display: none;

@import '@lora/05-components/search/search-plp-top-banner';