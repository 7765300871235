// PLP Top container
$plp-top-size: full;
$plp-slot-top-size: full;

$plp-tools-border-bottom--large: none;
// Top Slot
$plp-slot-top-margin: rem-calc(0);
$plp-slot-top-margin--large: rem-calc(0);
// Bottom description
$plp-bottom-description-margin: rem-calc(48 0);
$plp-bottom-description-margin--large: rem-calc(64 0);
$plp-bottom-description-padding: rem-calc(0 16);
$plp-bottom-description-padding--large: rem-calc(0);
$plp-bottom-description-font--large: #{rem-calc(14)} / 1.6 $font-primary;
$plp-bottom-description-text-align: center;
$plp-bottom-description-text-align--large: left;
// View swithcer
$plp-results-view-switcher-padding: 0;
$plp-results-view-switcher-border-right: 0;
// Comparison Toggle
$plp-comparison-toggle-font: bold #{rem-calc(12)} / normal $font-tertiary;
$plp-comparison-toggle-padding: rem-calc(12 15);
$plp-comparison-toggle-margin--large: rem-calc(0 30);
// Results Count
$plp-results-count-margin: rem-calc(0 30 0 0);

@import '@lora/04-layout/plp';